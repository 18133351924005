import React from 'react';

import IconSearch from '@components/icons/IconSearch';
import IconSQLQuery from '@components/icons/IconSQLQuery';
import MarketIcon from '@components/icons/IconMarket';
import Icon3RnDLogo from '@components/icons/Icon3RnDLogo';

export const asedaCards = [
  {
    title: 'Aseda Sciences Website',
    authorizedOnly: false,
    newTab: true,
    icon: <img src={'/img/AsedaSciences_logo.svg'} alt={'AsedaSciences'} width={64} />,
    link: 'https://asedasciences.com',
    description: 'Look at the main AsedaSciences website for more information',
  },
  {
    title: '3RnD Application',
    authorizedOnly: false,
    newTab: true,
    icon: <Icon3RnDLogo size={64} />,
    link: 'https://app-prd.thewarriors3.com',
    description: 'Coming Soon',
  },
  {
    title: 'Compound Marketplace',
    authorizedOnly: true,
    newTab: true,
    icon: <MarketIcon size={64} />,
    link: '',
    description: 'Coming Soon',
  },
];

export const chemHunterCards = [
  {
    title: "Search Databases",
    authorizedOnly: true,
    newTab: false,
    icon: <IconSearch size={64} />,
    link: "/submissions",
    description: "Search through the available database",
  },
  {
    title: "SQL Query (Expert)",
    authorizedOnly: true,
    newTab: false,
    icon: <IconSQLQuery size={64} />,
    link: "/submissions/create",
    description: "Direct access to the databases using an SQL query",
  }
];


