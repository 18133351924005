import HomeIcon from '@components/icons/IconHome';
import IconCheckList from '@components/icons/IconCheckList';
import IconCart from '@components/icons/IconCart';
import IconFullCart from '@components/icons/fullCart.icon';
import IconAddFile from '@components/icons/IconAddFile';
import IconInfo from '@components/icons/info.icon';
import IconSearch from "@components/icons/IconSearch";
import IconSQLQuery from "@components/icons/IconSQLQuery";

export const menuItems = [
  {
    id: 'home',
    name: 'Home',
    label: 'HM',
    pathname: '/home',
    icon: <HomeIcon />,
    authorizedOnly: false,
  },
  {
    id: 'searchDatabases',
    name: 'Search Databases',
    label: 'SDB',
    pathname: '/search',
    icon: <IconSearch />,
    authorizedOnly: true,
  },
  {
    id: 'sqlQuery',
    name: 'SQL Query (Expert)',
    label: 'SQL',
    pathname: '/sql',
    icon: <IconSQLQuery />,
    authorizedOnly: true,
  },
  {
    id: 'info',
    name: 'Info/FAQ',
    label: 'FAQ',
    pathname: '/info',
    icon: <IconInfo />,
    authorizedOnly: true,
  },
];
