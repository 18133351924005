import React, { useContext } from 'react';
import styled from "styled-components/macro";
import PageContainer from '../components/pages/PageContainer';
import PagePanel from "../components/pages/PagePanel";
import PageTiles from "@components/layout/PageTiles";
// import NewUser from '@components/user/NewUser';
import {UserContext} from "../contexts/UserContext";
import {asedaCards, chemHunterCards} from "@pages/config/cards.config";
import { MenuContext } from '../contexts/MenuContext';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgHome = () => {
  const { user } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  debug && console.log("PgHome | userInfo:", user);

  if (activeMenuId !== 'home') setActiveMenuId('home');

  return (
    <PageContainer title='Welcome'>
      {/* TODO - when subscriptions available -- check if new user
      <NewUser /> : null
      */}
      <PagePanel title={""}>
        <OverviewTitle>
          <B>E</B>vidence <B>A</B>ssessment <B>SY</B>stem for <B>Tox</B>icology Professionals
        </OverviewTitle>
        <OverviewText>
          <ul>
            <Goal>Harmonized Adversity Interpretation</Goal>
            <ul>
              <SubGoal>Organ/system to pathway level drill down</SubGoal>
              <SubGoal>Parameters & key resources for interpretation- centralized knowledgebase, expert informed</SubGoal>
              <SubGoal>Connection to relevant studies/tools</SubGoal>
            </ul>
            <Goal>How to identify and use NAMS</Goal>
            <SubGoal>Align to organ/system to pathway level drill down</SubGoal>
            <SubGoal>Provide study background/interpretation/quality guidance</SubGoal>
            <SubGoal>Facilitate orders for endpoint specific NAMs studies</SubGoal>
          </ul>
        </OverviewText>
      </PagePanel>
      <PagePanel title={"EASY Tox Capabilities"}>
        <PageTiles
            isAuthorized={user.isAppAuthorized}
            cards={chemHunterCards}
        />
      </PagePanel>
      <PagePanel title={"Additional AsedaSciences Information and Application/Services"}>
        <PageTiles
            isAuthorized={user.isAppAuthorized}
            cards={asedaCards}
        />
      </PagePanel>
    </PageContainer>
  );
};

export default PgHome;

const OverviewTitle = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.textSecondary };
  font-size: 18px;
`;

const B = styled.div`
  color: ${(p) => p.theme.palette.accentPrimary };
  font-weight: bold;
  margin-left: 5px;
`;

const OverviewText = styled.div`
  font-size: 14px;
`;

const Goal = styled.li`
  font-size: 14px;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const SubGoal = styled.li`
  font-size: 12px;
  margin-left: 5px;
  color: ${(p) => p.theme.palette.textSecondary}; 
  &:before {
    content: '* ';
    color: ${(p) => p.theme.palette.accentPrimary};
  }
`;

