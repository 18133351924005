import { useEffect, useState, useContext } from 'react';
import { APP_CONFIG } from '../../App.config';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import MenuButton from '@components/controls/MenuButton/MenuButton';
import { MenuContext } from "../../contexts/MenuContext";
import history from "history/browser";

const getMenuIdActive = (menu, pathname) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].pathname === pathname) {
      return menu[i].id;
    }
  }
  return null;
};

const debug = false;
// Side Menu -- changes whether the user is logged in or not
const SideNav = (props) => {
  const { menu, isAuthorized } = props;
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const [activeMenus, setActiveMenus] = useState([]);
  let pathname = history.location.pathname;

  debug && console.log('UserProfileMenu | isAuthorized:', isAuthorized);

  useEffect(() => {
    history.listen(({ location }) => {
      debug && console.log("history listen", location.pathname);
      const localActiveMenuId = getMenuIdActive(menu, location.pathname);
      setActiveMenuId(localActiveMenuId);
    });
  }, [activeMenuId, menu, pathname, setActiveMenuId, isAuthorized]);

  useEffect(() => {
    if (isAuthorized) {
      setActiveMenus(menu);
    } else {
      const filtered = menu.filter((obj) => !obj.authorizedOnly);
      setActiveMenus(filtered);
    }
  }, [menu, isAuthorized]);
  debug && console.log('SideNav | activeMenus:', activeMenus);

  return (
    <Container>
      <Menu>
        <LogoItem>
          <img src={APP_CONFIG.logo} alt='Logo' width={32} />
        </LogoItem>
        <SideMenu>
          <SideMenuItems>
            {activeMenus.map((menuItem) => {
              return (
                <Link
                    to={menuItem.pathname}
                    onClick={() => setActiveMenuId(menuItem.id)}
                    key={menuItem.id}
                >
                  <MenuButton
                    text={menuItem.name}
                    icon={menuItem.icon}
                    isActive={activeMenuId === menuItem.id}
                    tooltipPlacement='right'
                  />
                </Link>
              );
            })}
          </SideMenuItems>
        </SideMenu>
      </Menu>
    </Container>
  );
};

export default SideNav;

const Container = styled.div`
  width: 50px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 0;
  margin: 0;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};

  /* z-index: 105; */
  & ::-webkit-scrollbar {
    height: 0;
    width: 0;
  } /* hide scrollbar chrome */
`;

const LogoItem = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  height: 50px;
  justify-items: center;
`;

const SideMenu = styled.div`
  height: calc(100vh - 115px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex: none;
  flex-grow: 0;
`;

const SideMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  overflow-y: scroll;
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;
