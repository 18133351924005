import React, { useContext } from 'react';
import PageContainer from '@components/pages/PageContainer';
import PagePanel from "@components/pages/PagePanel";
import {UserContext} from "../contexts/UserContext";
import { MenuContext } from '../contexts/MenuContext';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgSearch = () => {
  const { user } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  debug && console.log("PgHome | userInfo:", user);

  if (activeMenuId !== 'home') setActiveMenuId('home');

  return (
    <PageContainer title='Search Available Databases'>
      <PagePanel title={"Search Databases"}>
        add content here
      </PagePanel>
    </PageContainer>
  );
};

export default PgSearch;

