import React from "react";
import styled from "styled-components/macro";
import TopMenu from "../menus/TopMenu";
import { FlexItem } from '@components/layout/FlexStyles';
import UserAccountPolicies from '@components/account/fields/UserAccountPolicies';

interface propsT {
  title: string;
  children: any;
}

// TODO: Update the calls to retrieve the Info
const PageContainer = (props: propsT) => {
  const { title } = props;
  const version = 'API:' + process.env.REACT_APP_API_HOST
      + '-UI:' + process.env.REACT_APP_HOST_TYPE
      + '-' + process.env.REACT_APP_VERSION;

  return (
    <Container>
      <TopMenu title={title} />
      <PageContent>
        {props.children}
      </PageContent>
      <PageFooter>
        <FlexItem></FlexItem>
        <UserAccountPolicies account={true}/>
        <Version>vers:{version}</Version></PageFooter>
    </Container>
  );
};

export default PageContainer;

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: calc(100vw - 50px);
  top: 0;
  padding: 0;
  margin: 0;
  left: 50px;
`;

const PageContent = styled.div`
  position: relative;
  width: calc(100vw - 60px);
  padding: 0;
  margin: 5px;
  flex: auto;
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const PageFooter = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 30px;
  bottom: 0;
  color: ${(p) => p.theme.palette.textQuaternary};
`;

const Version = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  width: 150px;
  font-size: 8px;
`;
