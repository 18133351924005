import React, { useContext } from "react";
import { StyleContext } from "@theme/AppStyles";

const IconSQLQuery = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill={colorHighlight} d="M20.7,22.8H3.3c-1.1,0-2.1-0.9-2.1-2.1V3.3c0-1.1,0.9-2.1,2.1-2.1h17.3c1.1,0,2.1,0.9,2.1,2.1v17.3
	C22.8,21.8,21.8,22.8,20.7,22.8z M3.3,2.8C3,2.8,2.8,3,2.8,3.3v17.3c0,0.3,0.3,0.6,0.6,0.6h17.3c0.3,0,0.6-0.3,0.6-0.6V3.3
	c0-0.3-0.3-0.6-0.6-0.6H3.3z"/>
          <path fill={colorHighlight} d="M11.4,12.6l0-0.5c-0.1-1,0.2-2,1.1-3.1c0.8-1,1.3-1.7,1.3-2.5c0-0.9-0.6-1.5-1.7-1.5c-0.6,0-1.4,0.2-1.8,0.6L9.8,4.5
	c0.6-0.4,1.6-0.7,2.6-0.7c2.1,0,3,1.3,3,2.6c0,1.2-0.7,2.1-1.5,3.1c-0.8,0.9-1.1,1.7-1,2.6l0,0.5H11.4z M11,15.2
	c0-0.7,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.4,1.1-1.1,1.1C11.4,16.3,11,15.8,11,15.2z"/>
          <path fill={color} d="M7.1,15.7c-0.8-0.3-1.2-0.6-1.2-1.2c0-0.4,0.3-0.9,1.1-0.9c0.5,0,1,0.2,1.2,0.3l0.2-0.6C8.1,13.1,7.6,13,7,13
	c-1.2,0-2,0.7-2,1.6c0,0.9,0.6,1.4,1.6,1.7c0.8,0.3,1.1,0.6,1.1,1.2c0,0.6-0.5,1-1.3,1c-0.5,0-1.1-0.2-1.4-0.4l-0.2,0.7
	c0.3,0.2,1,0.4,1.5,0.4c1.4,0,2.1-0.8,2.1-1.7C8.6,16.6,8.1,16.1,7.1,15.7z"/>
          <path fill={color} d="M13.1,19c1-0.4,1.7-1.4,1.7-2.9c0-1.8-1.1-3.1-2.7-3.1c-1.6,0-2.8,1.3-2.8,3.2c0,2,1.2,3,2.7,3.1c0.1,0,0.2,0,0.4,0.1
	c0.7,0.2,1.5,0.5,2.3,0.7l0.2-0.7C14.4,19.3,13.7,19.2,13.1,19L13.1,19z M12.1,18.6c-1.2,0-1.9-1.2-1.9-2.4c0-1.3,0.6-2.5,2-2.5
	c1.3,0,1.9,1.3,1.9,2.5C14,17.5,13.3,18.6,12.1,18.6z"/>
      </svg>
    </>
  );
};

export default IconSQLQuery;
