import React, { memo, useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '../contexts/UserContext';
import StationaryModal from './components/StationaryModal';
import { Close } from '@mui/icons-material';
import BugsIcon from './bugs.icon';
import BugsSubmit from './bugs.submit';
import BugsList from './bugs.list';
import { BugsModalI } from './bugs.types';
import { BugReports } from '@utils/api';

export const BugsModal = memo(({ modalOpen, setModalOpen }: BugsModalI) => {
  const [newBugReported, setNewBugReported] = useState(false); // used to refresh list
  const [inEditBug, setInEditBug] = useState();
  const [deletedBugId, setDeletedBugId] = useState();
  const { user } = useContext(UserContext);

  const onActionBug = async (bug: any, type: 'delete' | 'edit') => {
    if (type === 'edit') {
      setInEditBug(bug);
    } else if (type === 'delete') {
      const token = user.authSession.getAccessToken().getJwtToken();
      try {
        await BugReports.delete(token, { uuid: bug.uuid });
        setDeletedBugId(bug.uuid);
      } catch (err) {
        console.log('error: ', err);
      }
    }
  };

  return (
    <StationaryModal
      title={'Submit a Bug Report'}
      opacity={0.95}
      modalOpen={modalOpen}
      onCloseClick={() => setModalOpen(false)}
    >
      <InnerPanel>
        <ModalTitle>
          <ModalLabel>
            <StyledBugsIcon size={24} />
            <Title>Bugs Reporting</Title>
          </ModalLabel>
          <TitleIcon>
            <Close onClick={() => setModalOpen(false)} />
          </TitleIcon>
        </ModalTitle>
        <PanelBody>
          <PanelItem>
            <BugsSubmit
              onSubmit={() => setModalOpen(false)}
              setNewBugReported={setNewBugReported}
              inEditBug={inEditBug}
              setInEditBug={setInEditBug}
            />
          </PanelItem>
          <PanelItem>
            <BugsList
              onActionBug={onActionBug}
              inEditBug={inEditBug}
              newBugReported={newBugReported}
              deletedBugId={deletedBugId}
              setDeletedBugId={setDeletedBugId}
            />
          </PanelItem>
        </PanelBody>
      </InnerPanel>
    </StationaryModal>
  );
});

const InnerPanel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border-radius: 15px;
  border: 2px solid #636363;
  padding: 0;
  margin: 0;
`;

const ModalTitle = styled.div`
  align-items: center;
  position: relative;
  padding: 5px;
`;

const ModalLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  align-items: center;
  position: relative;
  padding: 5px;
`;

const StyledBugsIcon = styled(BugsIcon)`
  display: flex;
  position: relative;
  padding-right: 30px;
  padding-left: 5px;
`;

const Title = styled.div`
  display: flex;
  position: relative;
  font-size: 20px;
  max-width: 300px;
  margin-left: 10px;
`;

const TitleIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  filter: contrast(1) brightness(0.7);
  :hover {
    color: ${(props) => props.theme.palette.accentPrimary};
    filter: contrast(1) brightness(1);
  }
`;

const PanelBody = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  min-height: 300px;
`;

const PanelItem = styled.div`
  display: flex;
  margin-top: 5px;
  padding: 5px;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
`;
